import Login from "pages/Login";
import Register from "pages/Register";

const authRoutes = [
  {
    path: "/Login",
    name: "Login",
    icon: "design_app",
    component: Login,
    layout: "/admin",
  },
  {
    path: "/Cadastro",
    name: "Cadastro",
    icon: "design_app",
    component: Register,
    layout: "/admin",
  },
];
export default authRoutes;
