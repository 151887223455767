import React, { createContext, useContext, useEffect, useState } from "react";
import api from "../services/api";

interface AuthContextDTO {
  signed: boolean;
  user: string;
  warning: boolean;
  Login: (user: string, password: string) => void;
  Get: (page: string) => void;
  Logout: () => void;
}
const AuthContext = createContext<AuthContextDTO>({} as AuthContextDTO);

export const AuthProvider: React.FC = ({ children }) => {
  const [signed, setSign] = useState(false);
  const [user, setUser] = useState("");
  const [warning, setWarning] = useState(false);

  useEffect(() => {
    const storagedToken = localStorage.getItem("@App:token");

    if (storagedToken) {
      api.defaults.headers.Authorization = `token ${storagedToken}`;
      setSign(true);
    }
  }, []);

  const Login = async (user: string, password: string) => {
    const storagedToken = localStorage.getItem("@App:token");

    if (storagedToken) {
      api.defaults.headers.Authorization = ``;
    }
    api
      .post("/web/login/", {
        username: user,
        password: password,
      })
      .then((response) => {
        setSign(true);
        console.log(response);
        localStorage.setItem("@App:token", response.data.token);
        api.defaults.headers.Authorization = `token ${response.data.token}`;
        setWarning(false);
        setUser(user);
      })
      .catch(() => {
        setWarning(true);
      });
  };

  const Logout = () => {
    setSign(false);
    sessionStorage.removeItem("App:token");
  };

  async function Get(page: string) {
    const response = await api.get(`/web/${page}/`);
    return response;
  }

  return (
    <AuthContext.Provider value={{ signed, Login, Get, Logout, user, warning }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextDTO => {
  const context = useContext(AuthContext);
  return context;
};
