import IWater from "interfaces/water";
import api from "./api";

export const getLastWater = async (): Promise<IWater> => {
  try {
    const { data } = await api.get("/web/last-data-water");
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

export const getFilteredWater = async (
  initial: string,
  end: string
): Promise<IWater[]> => {
  try {
    const { data } = await api.get(
      `/web/water/filter?initial_date=${initial}&final_date=${end}`
    );
    return data;
  } catch (e) {
    throw new Error(e);
  }
};
