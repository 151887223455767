import React, { useEffect, useState } from "react";
// react plugin used to create charts
import api from "../services/api";

// reactstrap components
import { Alert, Table, Input } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars";
import FormFeedback from "reactstrap/lib/FormFeedback";
import IUser from "interfaces/users";

const Usuarios = () => {
  const [visible, setVisible] = useState(false);
  const [users, setList] = useState<IUser[]>([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [sucess, setSucess] = useState(false);
  const onSucess = () => setSucess(false);

  useEffect(() => {
    atualize();
  }, []);

  function atualize() {
    api
      .get("/web/user/")
      .then((response) => {
        setList(response.data);
      })
      .catch(() => {
        setVisible(true);
      });
  }

  function exclude(id: string) {
    api.delete(`/web/user/${id}`);
  }
  function add() {
    api
      .post("/web/user/", {
        email: email,
        password: password,
      })
      .then(() => {
        setPassword("");
        setEmail("");
        setSucess(true);
        setVisible(false);
        atualize();
      })
      .catch(() => {
        setVisible(true);
      });
  }

  return (
    <>
      <DemoNavbar page={2} />
      <Alert
        color="sucess"
        isOpen={sucess}
        toggle={onSucess}
        className="text-center"
      >
        Usuário cadastrado com sucesso
      </Alert>
      <Table striped>
        <thead className="text-center">
          <tr>
            <th>
              {!visible ? (
                <Input
                  type="email"
                  name="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  placeholder="E-mail"
                />
              ) : (
                <>
                  <Input
                    invalid
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <FormFeedback>Digite um e-mail válido</FormFeedback>{" "}
                </>
              )}
            </th>
            <th>
              <Input
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                name="password"
                placeholder="Senha"
              />
            </th>
            <th>{<button onClick={add}> Adicionar </button>} </th>
          </tr>
          <tr>
            <th>id</th>
            <th>E-mail</th>
            <th>Ação</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {users.map((User) => {
            return (
              <tr>
                <th scope="row">{User.id}</th>
                <td>{User.email}</td>
                <td>
                  <button
                    onClick={() => {
                      exclude(User.id);
                      atualize();
                    }}
                  >
                    Excluir
                  </button>{" "}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default Usuarios;
