import React from "react";

export default class SearchInput extends React.Component {

    constructor(props){
        super(props)
        this.state ={
            initial: this.props.initial,
            end: this.props.end
        }
    }
    


render(){
    return (
        <>
            <b>Data inicial:</b> <input type='date' value={this.state.initial} onChange={e =>  {this.setState({ initial: e.target.value}); this.props.setI( e.target.value)}} />
            <b>Data Final:</b> <input type='date' value={this.state.end} onChange={e => {this.setState({ end: e.target.value}); this.props.setE(e.target.value)}}/>
        </>
    );
}
}
