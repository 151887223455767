import React, { useRef, useEffect } from 'react'

const Canvas = props => {
    const proportion = props.od / props.scale

    const canvasRef = useRef(null)

    useEffect(() => {
        const draw = ctx => {
            ctx.fillStyle = '#6CAE02'
            ctx.fillRect(0, ctx.canvas.height - 30, proportion * ctx.canvas.width, 30)
            ctx.fillStyle = '#E9F0F7'
            ctx.fillRect(proportion * ctx.canvas.width, ctx.canvas.height - 30, (1 - proportion) * ctx.canvas.width, 30)
        }

        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        canvas.style.width = '100%';
        canvas.style.height = '60px';
        draw(context)
    }, [proportion])

    return (
        <div >
            <canvas ref={canvasRef} {...props} />
            <div className="Canvas-scale">
                <p>0</p>
                <p>50</p>
                <p>100</p>
            </div>
        </div>
    )
}

export default Canvas
