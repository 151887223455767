import React, { useState } from "react";
import { Doughnut, HorizontalBar } from "react-chartjs-2";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  Row,
  Col,
  Alert,
} from "reactstrap";

import Header from "components/Navbars";

import { gasLEL, Gases } from "variables/charts";
import Thermometer from "components/Progress/Thermometer";
import GoogleChart from "components/History/GoogleChart";
import { getFilteredGas, getLastGas } from "services/gas.service";
import { useQuery } from "react-query";

function DashboardGas() {
  const [visible, setVisible] = useState(false);

  const handleVisibility = () => setVisible(false);

  const initial = "01-05-2021";
  const end = "30-06-2021";

  const { data: lastGasData, error: lastGasError } = useQuery(
    "lastGasData-dashboardGas",
    getLastGas
  );

  const { data: filteredGasData, error: filteredGasError } = useQuery(
    "filteredGas-dashboardGas",
    () => getFilteredGas(initial, end),
    {
      enabled: Boolean(initial && end),
    }
  );

  if (lastGasError || filteredGasError) {
    setVisible(true);
  }

  return (
    <>
      <Header page={1} />
      <div className="content">
        <Alert
          color="danger"
          isOpen={visible}
          toggle={handleVisibility}
          className="text-center"
        >
          Erro ao estabelecer conexão com o banco de dados
        </Alert>

        <Row>
          <Col xs={12} md={4}>
            <Card body className="card-chart">
              <CardHeader>
                <CardTitle tag="h4" className="text-center">
                  <b>Benzeno</b>
                </CardTitle>
              </CardHeader>
              <CardBody className="card-text-center">
                <CardText className="text-center" tag="h1">
                  <b>{lastGasData?.benzene?.toFixed(2)}</b> ppm
                </CardText>
              </CardBody>
            </Card>
            <Card body className="card-chart">
              <CardHeader>
                <CardTitle tag="h4" className="text-center">
                  <b>LEL dos Gases</b>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Doughnut
                  data={gasLEL.data([
                    lastGasData?.lel,
                    500 - lastGasData?.lel!,
                  ])}
                  options={gasLEL.options}
                />
                <CardText className="text-center" tag="h1" id="center-donut">
                  <b>{((100 * lastGasData?.lel!) / 500).toFixed(2)}%</b>
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card body className="card-chart">
              <CardHeader>
                <CardTitle tag="h4" className="text-center">
                  <b>Compostos Orgânicos Voláteis</b>
                </CardTitle>
              </CardHeader>
              <CardBody className="card-text-center">
                <CardText className="text-center" tag="h1">
                  <b>{lastGasData?.voc?.toFixed(2)}</b> ppm
                </CardText>
              </CardBody>
            </Card>
            <Card body className="card-chart">
              <CardHeader>
                <CardTitle tag="h4" className="text-center">
                  <b>Percentual dos Gases</b>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <HorizontalBar
                  data={Gases.data([
                    lastGasData?.co2,
                    lastGasData?.co,
                    lastGasData?.o2,
                  ])}
                  options={Gases.options}
                />
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="card-chart side-chart">
              <CardHeader>
                <CardTitle tag="h4" className="text-center">
                  Temperatura dos Gases
                </CardTitle>
              </CardHeader>
              <CardBody id="thermometer">
                <CardText tag="h1">
                  <b>{lastGasData?.temperature?.toFixed(1)}</b>ºC
                </CardText>
                <Thermometer
                  temperature={lastGasData?.temperature}
                  scale={100}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row tag="h2"> Histórico Diário</Row>
        <Row>
          <Col xs={12} md={6}>
            <GoogleChart
              data={filteredGasData!}
              date={[initial, end]}
              name="Benzeno"
              property={["benzene"]}
              type="gas"
            />
            <GoogleChart
              data={filteredGasData!}
              date={[initial, end]}
              name="Compostos Orgânicos Voláteis"
              property={["voc"]}
              type="gas"
            />
          </Col>
          <Col xs={12} md={6}>
            <GoogleChart
              data={filteredGasData!}
              date={[initial, end]}
              name="Temperatura dos Gases"
              property={["temperature"]}
              type="gas"
            />
            <GoogleChart
              data={filteredGasData!}
              date={[initial, end]}
              name="LEL dos Gases"
              property={["lel"]}
              type="gas"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={{ size: 8, offset: 2 }}>
            <GoogleChart
              data={filteredGasData!}
              date={[initial, end]}
              name="Percentual dos Gases"
              property={["co2", "co", "o2"]}
              type="gas"
              gas={true}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default DashboardGas;
