import React from "react";
import Auth from "../layouts/LoginAdmin";
import Dashboard from "../layouts/ChartAdmin";
import { useAuth } from "hooks/useAuth";

const Routes = () => {
  const { signed } = useAuth();

  return signed ? <Dashboard /> : <Auth />;
};

export default Routes;
