import React from "react";
// reactstrap components
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";

// core components

import dashRoutes from "../routes/dashRoutes";

function Dashboard() {
  return (
    <div className="wrapper">
      <div className="side-menu" />
      <div className="main-panel">
        <BrowserRouter>
          <Switch>
            {dashRoutes.map((prop, key) => {
              return (
                <Route
                  path={prop.layout + prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            })}
            <Redirect from="/admin" to="/admin/Dashboard/Agua" />
          </Switch>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default Dashboard;
