import DashboardAgua from "pages/DashboardAgua";
import DashboardGas from "pages/DashboardGas";
import Usuarios from "pages/Usuarios";

const dashRoutes = [
  {
    path: "/Dashboard/Agua",
    name: "DashboardAgua",
    icon: "design_app",
    component: DashboardAgua,
    layout: "/admin",
  },
  {
    path: "/Dashboard/Gas",
    name: "DashboardGas",
    icon: "design_app",
    component: DashboardGas,
    layout: "/admin",
  },
  {
    path: "/Usuarios",
    name: "Usuários",
    icon: "design_app",
    component: Usuarios,
    layout: "/admin",
  },
];
export default dashRoutes;
