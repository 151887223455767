import IGas from "interfaces/gas";
import api from "./api";

export const getLastGas = async (): Promise<IGas> => {
  try {
    const { data } = await api.get("/web/last-data-gas");
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

export const getFilteredGas = async (
  initial: string,
  end: string
): Promise<IGas[]> => {
  try {
    const { data } = await api.get(
      `/web/gas/filter?initial_date=${initial}&final_date=${end}`
    );

    return data;
  } catch (e) {
    throw new Error(e);
  }
};
