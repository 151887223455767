import React, { useState } from "react";
import Logo from "../GreenSoil.svg";
import { useAuth } from "../hooks/useAuth";

function Register() {
  const [username, setUser] = useState("");
  const [password, setPassword] = useState("");
  const { Login } = useAuth();

  function Submit() {
    Login(username, password);
  }

  return (
    <div className="login-page">
      <div className="login-info">
        <div className="login-input">
          <h1>Login</h1>
          <input
            type="text"
            placeholder="Email"
            onChange={(e) => {
              setUser(e.target.value);
            }}
          />
          <input
            type="password"
            placeholder="Senha"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <button onClick={Submit}>Cadastrar</button>
        </div>
      </div>
      <div className="login-logo">
        <img src={Logo} alt={"Logo Green Soil"} />
      </div>
    </div>
  );
}

export default Register;
