import React, { useState } from "react";
import Logo from "../GreenSoil.svg";
import { Alert } from "reactstrap";
import { useAuth } from "hooks/useAuth";

const Login = () => {
  const [username, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);

  const { Login, warning } = useAuth();

  async function Submit() {
    Login(username, password);
    if (warning) setVisible(true);
  }

  return (
    <div className="login-page">
      <div className="login-info">
        <div className="login-input">
          <h1>Login</h1>
          <Alert color="danger" isOpen={visible} className="text-center">
            E-mail ou senha incorretos
          </Alert>
          <input
            type="text"
            placeholder="Email"
            onChange={(e) => {
              setUser(e.target.value);
            }}
          />
          <input
            type="password"
            placeholder="Senha"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <button onClick={Submit}>Logar</button>
        </div>
      </div>
      <div className="login-logo">
        <img src={Logo} alt={"Logo Green Soil"} />
      </div>
    </div>
  );
};

export default Login;
