import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BsXCircle } from "react-icons/bs";
import { IconContext } from "react-icons";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Container,
} from "reactstrap";

import Brand from "../../GreenSoil.svg";
import { useAuth } from "hooks/useAuth";

type HeaderProps = {
  page: number;
};
const Header = ({ page }: HeaderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { Logout } = useAuth();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Navbar expand="lg">
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-toggle"></div>
          <NavbarBrand href="/">
            <img src={Brand} height="50px" alt="GreenSoil" />
          </NavbarBrand>
        </div>
        <NavbarToggler onClick={handleToggle}>
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          <Nav>
            <Link to="/admin/Dashboard/Agua" className="nav-link">
              {page === 0 ? <mark>Análise da Água</mark> : "Análise da Água"}
            </Link>
            <Link to="/admin/Dashboard/Gas" className="nav-link">
              {page === 1 ? (
                <mark>Análise dos Gases</mark>
              ) : (
                "Análise dos Gases"
              )}
            </Link>
            <Link to="/admin/Usuarios" className="nav-link">
              {page === 2 ? <mark>Usuários</mark> : "Usuários"}
            </Link>
            <IconContext.Provider value={{ color: "white", size: "2em" }}>
              <div style={{ alignSelf: "center" }}>
                <BsXCircle onClick={Logout} />
              </div>
            </IconContext.Provider>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
