import React from "react";

// reactstrap components
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";

// core components
import authRoutes from "../routes/authRoutes";

function Auth() {
  return (
    <div className="wrapper">
      <div className="side-menu" />
      <div className="main-panel">
        <BrowserRouter>
          <Switch>
            {authRoutes.map((prop, key) => {
              return (
                <Route
                  path={prop.layout + prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            })}
            <Redirect to="/admin/Login" />
          </Switch>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default Auth;
