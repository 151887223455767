// ##############################
// // // Function that converts a hex color number to a RGB color number
// #############################
export function hexToRGB(hex: string, alpha: number) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

// ##############################
// // // general variables for charts
// #############################

const chartColor = "#FFFFFF";
const color = ["#6CAE02", "#A3F223", "#426B00"];

var gradientChartOptionsConfigurationWithNumbersAndGrid = {
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      anchor: "end",
      align: "start",
      offset: -20,
      font: {
        size: 10,
      },
    },
  },
  legend: {
    display: false,
  },
  tooltips: {
    bodySpacing: 4,
    mode: "nearest",
    intersect: 0,
    position: "nearest",
    xPadding: 20,
    yPadding: 20,
    caretPadding: 10,
  },
  responsive: 1,
  scales: {
    yAxes: [
      {
        gridLines: {
          zeroLineColor: "transparent",
          drawBorder: true,
        },
        ticks: {
          maxTicksLimit: 7,
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          display: true,
        },
        gridLines: {
          zeroLineColor: "transparent",
          drawTicks: false,
          display: false,
          drawBorder: false,
        },
      },
    ],
  },
  layout: {
    padding: { left: 0, right: 0, top: 15, bottom: 15 },
  },
};

// ##############################
// // // Dashboard view - All Products - Card
// #############################

export const HistoryChart = {
  //29-03-2021T20:00:44
  data: (responseList: any, property: any) => {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    var gradientStroke = ctx?.createLinearGradient(500, 0, 100, 0);
    gradientStroke?.addColorStop(0, "#6CAE02");
    gradientStroke?.addColorStop(1, chartColor);
    var gradientFill = ctx?.createLinearGradient(0, 170, 0, 50);
    gradientFill?.addColorStop(0, "rgba(128, 182, 244, 0)");
    gradientFill?.addColorStop(1, hexToRGB("#6CAE02", 0.4));
    return {
      labels: responseList
        ? responseList.map((object: any) => {
            return (
              object.date.slice(5, 10).split("-").reverse().join("/") +
              " " +
              object.date.split(":")[0].slice(-2) +
              ":" +
              object.date.split(":")[1]
            );
          })
        : "",
      datasets: property.map((prop: any, index: number) => {
        return {
          label: prop,
          borderColor: color[index],
          pointBorderColor: "#FFF",
          pointBackgroundColor: color[index],
          pointBorderWidth: 2,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 1,
          pointRadius: 4,
          fill: true,
          backgroundColor: property.length > 1 ? "transparent" : gradientFill,
          borderWidth: 2,
          data: responseList.map((object: any) => object[prop]),
        };
      }),
    };
  },
  options: gradientChartOptionsConfigurationWithNumbersAndGrid,
};

// ##############################
// // // Dashboard view - Bar Chart - Card
// #############################

export const AguaPh = {
  data: (ph: any) => {
    return {
      labels: ["Ph"],
      datasets: [
        {
          label: "# of Votes",
          data: ph,
          backgroundColor: ["#6CAE02", "#E9F0F7"],
          borderColor: ["rgba(255, 255, 255 ,1)"],
          borderWidth: 2,
        },
      ],
      text: "9%",
    };
  },
  options: {
    maintainAspectRatio: false,
    rotation: 1 * Math.PI,
    circumference: 1 * Math.PI,
    plugins: {
      datalabels: {
        display: false,
      },
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    cutoutPercentage: 90,
    layout: {
      padding: { left: 0, right: 0, top: 15, bottom: 15 },
    },
  },
};
export const gasLEL = {
  data: (lel: any) => {
    return {
      labels: ["LEL Gases"],
      datasets: [
        {
          label: "# of Votes",
          data: lel,
          backgroundColor: ["#6CAE02", "#E9F0F7"],
          borderColor: ["rgba(255, 255, 255 ,1)"],
          borderWidth: 2,
        },
      ],
      text: "9%",
    };
  },
  options: {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    cutoutPercentage: 70,
    layout: {
      padding: { left: 0, right: 0, top: 15, bottom: 15 },
    },
  },
};

export const O2Dissolvido = {
  data: (canvas: HTMLCanvasElement) => {
    var ctx = canvas.getContext("2d");
    var gradientFill = ctx?.createLinearGradient(0, 170, 0, 50);
    gradientFill?.addColorStop(0, "rgba(128, 182, 244, 0)");
    gradientFill?.addColorStop(1, hexToRGB("#2CA8FF", 0.6));
    return {
      labels: ["O2"],
      datasets: [
        {
          label: "Active Countries",
          backgroundColor: gradientFill,
          borderColor: "#2CA8FF",
          pointBorderColor: "#FFF",
          pointBackgroundColor: "#2CA8FF",
          pointBorderWidth: 2,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 1,
          pointRadius: 4,
          fill: true,
          borderWidth: 1,
          data: [80],
        },
      ],
    };
  },
  options: {
    maintainAspectRatio: false,
    legend: {
      display: true,
    },
    tooltips: {
      bodySpacing: 4,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
      xPadding: 10,
      yPadding: 10,
      caretPadding: 10,
    },
    responsive: 1,
    scales: {
      yAxes: [
        {
          barPercentage: 0.2,
          ticks: {
            maxTicksLimit: 7,
          },
          gridLines: {
            zeroLineColor: "transparent",
            drawBorder: false,
          },
        },
      ],
      xAxes: [
        {
          barPercentage: 0.4,
          display: 0,
          ticks: {
            display: true,
          },
          gridLines: {
            zeroLineColor: "transparent",
            drawTicks: false,
            display: false,
            drawBorder: false,
          },
        },
      ],
    },
    layout: {
      padding: { left: 0, right: 0, top: 15, bottom: 15 },
    },
  },
};

export const Gases = {
  data: (gases: any) => {
    return {
      labels: ["CO2", "CO", "O2"],
      datasets: [
        {
          label: ["CO2", "CO", "O2"],
          data: gases,
          backgroundColor: ["#6CAE02", "#426B00", "#A3F223"],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
          ],
          borderWidth: 1,
        },
      ],
    };
  },
  options: {
    maintainAspectRatio: true,
    plugins: {
      datalabels: {
        color: "#607080",
        anchor: "end",
        align: "start",
        offset: -35,
        font: {
          weight: "bold",
          size: 20,
          family: "IBM Plex Sans",
        },
      },
    },
    legend: {
      display: false,
    },
    tooltips: {
      bodySpacing: 4,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
      xPadding: 10,
      yPadding: 10,
      caretPadding: 10,
    },
    responsive: 1,
    scales: {
      yAxes: [
        {
          barPercentage: 0.8,
          ticks: {
            maxTicksLimit: 7,
          },
          gridLines: {
            zeroLineColor: "transparent",
            drawBorder: false,
            display: false,
          },
        },
      ],
      xAxes: [
        {
          barPercentage: 0.6,
          display: 0,
          ticks: {
            display: false,
            max: 30,
          },
          gridLines: {
            zeroLineColor: "transparent",
            drawTicks: false,
            display: false,
            drawBorder: false,
          },
        },
      ],
    },
    layout: {
      padding: { left: 0, right: 0, top: 15, bottom: 15 },
    },
  },
};
