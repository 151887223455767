import React, { useRef, useEffect } from 'react'

const Thermometer = props => {

    const proportion = props.temperature / props.scale
    const canvasRef = useRef(null)



    useEffect(() => {
        const draw = ctx => {
            ctx.fillStyle = '#6CAE02'
            ctx.fillRect((ctx.canvas.width / 2 - 15), 0, 30, ctx.canvas.height - 52)
            ctx.fillStyle = '#E9F0F7'
            ctx.fillRect((ctx.canvas.width / 2 - 15), 0, 30, (1 - proportion) * (ctx.canvas.height - 52))
            ctx.fillStyle = '#6CAE02'
            ctx.beginPath()
            ctx.arc(ctx.canvas.width / 2, ctx.canvas.width - 30, 30, 0, 2 * Math.PI)
            ctx.fill()

            ctx.fillStyle = '#000000'
            ctx.font = "16px Serif";
            ctx.fillText("100", (ctx.canvas.width / 2 - 54), 16)
            ctx.fillText(" 50", (ctx.canvas.width / 2 - 54), (ctx.canvas.width / 2 - 16))
            ctx.fillText("  0", (ctx.canvas.width / 2 - 54), ctx.canvas.width - 54)

        }

        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        canvas.height = 300;
        canvas.style.width = '100%';
        canvas.style.height = '400px';
        canvas.style.marginRight = '-15%';

        //Our draw come here
        draw(context)
    }, [proportion])

    return (<>
        <canvas ref={canvasRef} {...props} />
        {/* <div className="Thermometer-scale">
            <p>0</p>
            <p>50</p>
            <p>100</p>
        </div> */}
    </>)
}


export default Thermometer;