import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  Row,
  Col,
  Alert,
} from "reactstrap";

import Canvas from "components/Progress/Canvas";
import Header from "components/Navbars";
import { AguaPh } from "variables/charts";
import Thermometer from "components/Progress/Thermometer";
import GoogleChart from "../components/History/GoogleChart";
import { getFilteredWater, getLastWater } from "services/water.service";
import { useQuery } from "react-query";
import { createInitialDate, createEndDate } from "shared/createDate";

const DashboardAgua = () => {
  const [visible, setVisible] = useState(false);

  const { data: lastWaterData } = useQuery(
    "getLastWater-gasDash",
    getLastWater
  );

  const handleVisibility = () => setVisible(false);

  const initial = createInitialDate();
  const end = createEndDate();

  const { data: waterHistoryData } = useQuery(
    "getfilteredAgua-dashboard",
    () => getFilteredWater(initial, end),
    { enabled: Boolean(initial && end) }
  );

  return (
    <>
      <Header page={0} />
      <div className="content">
        <Alert
          color="danger"
          isOpen={visible}
          toggle={handleVisibility}
          className="text-center"
        >
          Erro ao estabelecer conexão com o banco de dados!
        </Alert>

        <Row>
          <Col xs={12} md={4}>
            <Card body className="card-chart">
              <CardHeader>
                <CardTitle tag="h4" className="text-center">
                  <b>Condutividade da Água</b>
                </CardTitle>
              </CardHeader>
              <CardBody className="card-text-center">
                <CardText className="text-center" tag="h1">
                  <b>{lastWaterData?.condutivity?.toFixed(2)}</b> ms/cm
                </CardText>
              </CardBody>
            </Card>
            <Card body className="card-chart">
              <CardHeader>
                <CardTitle tag="h4" className="text-center">
                  <b>pH da Água</b>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Doughnut
                  data={AguaPh.data([
                    lastWaterData?.ph,
                    14 - lastWaterData?.ph!,
                  ])}
                  options={AguaPh.options}
                />
                <CardText className="text-center" tag="h1" id="ph-center">
                  <b>{lastWaterData?.ph?.toFixed(2)}</b>
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card body className="card-chart">
              <CardHeader>
                <CardTitle tag="h4" className="text-center">
                  <b>ORP da Água</b>
                </CardTitle>
              </CardHeader>
              <CardBody className="card-text-center">
                <CardText className="text-center" tag="h1">
                  <b>+{lastWaterData?.orp?.toFixed(2)}</b>mV
                </CardText>
              </CardBody>
            </Card>
            <Card body className="card-chart">
              <CardHeader>
                <CardTitle tag="h4" className="text-center">
                  <b>O2 dissolvido na Água</b>
                </CardTitle>
              </CardHeader>
              <CardBody className="Progress-div">
                <Canvas od={lastWaterData?.orp} scale={100} />
                <CardText className="text-center" tag="h1">
                  <b>{lastWaterData?.orp?.toFixed(2)}</b> mg/L
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card className="card-chart side-chart">
              <CardHeader>
                <CardTitle tag="h4" className="text-center">
                  Temperatura da Água
                </CardTitle>
              </CardHeader>
              <CardBody id="thermometer">
                <CardText tag="h1">
                  <b>{lastWaterData?.temperature?.toFixed(2)}</b>ºC
                </CardText>
                <Thermometer
                  temperature={lastWaterData?.temperature?.toFixed(2)}
                  scale={100}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row tag="h2"> Histórico Diário</Row>
        <Row>
          <Col xs={12} md={6}>
            <GoogleChart
              data={waterHistoryData}
              date={[initial, end]}
              name="Condutividade da Água"
              property={["condutivity"]}
              type="water"
            />
            <GoogleChart
              data={lastWaterData?.date}
              date={[initial, end]}
              name="Temperatura da Água"
              property={["temperature"]}
              type="water"
            />
          </Col>

          <Col xs={12} md={6}>
            <GoogleChart
              data={lastWaterData?.date}
              date={[initial, end]}
              name="ORP da Água"
              property={["orp"]}
              type="water"
            />
            <GoogleChart
              data={lastWaterData?.date}
              date={[initial, end]}
              name="Percentual da Água"
              property={["od"]}
              type="water"
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DashboardAgua;
