import React from 'react';
import { CSVLink } from 'react-csv';
import Chart from 'react-google-charts';
import api from '../../services/api';

import SearchInput from './SearchDate';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
  Alert,
  Button,
} from 'reactstrap';

class GoogleChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
      initial: this.fixDate(this.props.date[0]),
      end: this.fixDate(this.props.date[1]),
      max: [0, 0, 0],
      min: [0, 0, 0],
      media: [0, 0, 0],
      visible: false,
      chartData: [
        [
          { type: 'date', label: 'Data' },
          { type: 'number', label: 'Valor' },
        ],
      ],
    };
  }

  componentDidMount() {
    this.filter(this.state.initial, this.state.end);
  }

  csv() {
    return {
      filename: 'Report.csv',
      headers: [
        ...this.props.property.map((prop) => {
          return { label: prop, key: prop };
        }),
        { label: 'date', key: 'date' },
      ],
      data: this.state.value,
      separator: ';',
    };
  }

  onDismiss() {
    this.setState({ visible: false });
  }

  setInitial = (i) => {
    this.setState({ initial: i });
  };
  setEnd = (e) => {
    this.setState({ end: e });
  };

  fixDate(dateString) {
    const n = dateString.split('-');
    return `${n[2]}-${n[1]}-${n[0]}`;
  }

  async filter(dateInitial, dateEnd) {
    if (dateInitial !== '' && dateEnd !== '') {
      const arrI = dateInitial.split('-');
      const arrE = dateEnd.split('-');
      const dateI = new Date(+arrI[0], +arrI[1], +arrI[2]);
      const InitialDate = `${dateI
        .getDate()
        .toString()
        .padStart(2, '0')}-${dateI
        .getMonth()
        .toString()
        .padStart(2, '0')}-${dateI.getFullYear()}`;
      const dateE = new Date(+arrE[0], +arrE[1], +arrE[2]);
      if (Date.parse(dateE) - Date.parse(dateI) >= 0) {
        const FinalDate = `${dateE
          .getDate()
          .toString()
          .padStart(2, '0')}-${dateE
          .getMonth()
          .toString()
          .padStart(2, '0')}-${dateE.getFullYear()}`;
        api
          .get(
            `/web/${this.props.type}/filter?initial_date=${InitialDate}&final_date=${FinalDate}`
          )
          .then((response) => {
            this.setState({ value: response.data });
            //this.props.setValue(response.data)
            this.atualize();
          });
      } else {
        this.setState({ visible: true });
      }
    }
  }

  atualize() {
    let tamanho = this.state.value.length;
    let Max = tamanho
      ? tamanho > 1
        ? [
            this.state.value[tamanho - 1][this.props.property[0]],
            this.state.value[tamanho - 1][this.props.property[1]],
            this.state.value[tamanho - 1][this.props.property[2]],
          ]
        : [this.state.value[tamanho - 1][this.props.property[0]]]
      : [0, 0, 0];
    let Min = tamanho
      ? tamanho > 1
        ? [
            this.state.value[tamanho - 1][this.props.property[0]],
            this.state.value[tamanho - 1][this.props.property[1]],
            this.state.value[tamanho - 1][this.props.property[2]],
          ]
        : [this.state.value[tamanho - 1][this.props.property[0]]]
      : [0, 0, 0];
    let Media = tamanho
      ? tamanho > 1
        ? [
            this.state.value[tamanho - 1][this.props.property[0]],
            this.state.value[tamanho - 1][this.props.property[1]],
            this.state.value[tamanho - 1][this.props.property[2]],
          ]
        : [this.state.value[tamanho - 1][this.props.property[0]]]
      : [0, 0, 0];
    this.setState({ max: Max });
    this.setState({ min: Min });
    this.setState({ media: Media });

    const chartD = [[{ type: 'datetime', label: 'Data' }]];
    this.props.property.forEach((prop, index) => {
      chartD[0].push({ type: 'number', label: prop });

      this.state.value.forEach((object) => {
        if (object[prop] > this.state.max[index]) {
          let clone = [...this.state.max];
          clone[index] = object[prop];
          this.setState({ max: clone });
        }
        if (object[prop] < this.state.min[index]) {
          let clone = [...this.state.min];
          clone[index] = object[prop];
          this.setState({ min: clone });
        }

        // let clone = [...this.state.media];
        // clone[index] += object[prop] / this.state.value.length;
        // this.setState({ media: clone });

        // this.state.media[index] += object[prop] / this.state.value.length; //2021-05-11T18:22:48.842555
        if (this.props.property.length === 1) {
          chartD.push([new Date(object.date), object[this.props.property[0]]]);
        } else {
          if (index === 2)
            chartD.push([
              new Date(object.date),
              object[this.props.property[0]],
              object[this.props.property[1]],
              object[this.props.property[2]],
            ]);
        }
      });
    });
    this.setState({ chartData: chartD });
  }

  render() {
    return (
      <Card className="card-chart history-card">
        <CardHeader>
          <CardTitle tag="h4" className="text-center">
            <b>{this.props.name}</b>
          </CardTitle>
          <div className="medias-title">
            <div className="medias">
              <CardSubtitle>
                <b>Média:</b> {this.state.media[0].toFixed(2)}
              </CardSubtitle>
              <CardSubtitle>
                <b>Menor Valor:</b> {this.state.min[0].toFixed(2)}
              </CardSubtitle>
              <CardSubtitle>
                <b>Maior Valor:</b> {this.state.max[0].toFixed(2)}
              </CardSubtitle>
            </div>
            {this.props.gas && (
              <>
                <div className="medias">
                  <CardSubtitle>
                    <b>Média:</b> {this.state.media[1].toFixed(2) | ''}
                  </CardSubtitle>
                  <CardSubtitle>
                    <b>Menor Valor:</b> {this.state.min[1].toFixed(2)}
                  </CardSubtitle>
                  <CardSubtitle>
                    <b>Maior Valor:</b> {this.state.max[1].toFixed(2)}
                  </CardSubtitle>
                </div>
                <div className="medias">
                  <CardSubtitle>
                    <b>Média:</b> {this.state.media[2].toFixed(2) | ''}
                  </CardSubtitle>
                  <CardSubtitle>
                    <b>Menor Valor:</b> {this.state.min[2].toFixed(2)}
                  </CardSubtitle>
                  <CardSubtitle>
                    <b>Maior Valor:</b> {this.state.max[2].toFixed(2)}
                  </CardSubtitle>
                </div>
              </>
            )}
            <CardSubtitle>
              <div>
                <SearchInput
                  initial={this.state.initial}
                  end={this.state.end}
                  setI={this.setInitial}
                  setE={this.setEnd}
                />
              </div>
              <div className="Search-area">
                <CSVLink {...this.csv()}>Click to export</CSVLink>
                <Button
                  color="success"
                  onClick={() => {
                    this.filter(this.state.initial, this.state.end);
                  }}
                >
                  Pesquisar
                </Button>
              </div>
            </CardSubtitle>
          </div>
          <Alert
            color="danger"
            isOpen={this.state.visible}
            toggle={this.onDismiss}
            className="text-center"
          >
            A data inicial não pode ser posterior à data final !
          </Alert>
        </CardHeader>
        <CardBody>
          <div className="chart-area">
            <Chart
              width={'100%'}
              height={'200px'}
              chartType="LineChart"
              data={this.state.chartData}
              options={{
                hAxis: {
                  title: 'Data',
                  //format: 'M/d/yy',
                  gridlines: {
                    count: -1,
                    units: {
                      days: { format: ['MMM dd'] },
                      hours: { format: ['HH:mm', 'ha'] },
                    },
                  },
                },
                vAxis: {
                  title: 'Valor',
                },
                colors: ['#6CAE02', '#A3F223', '#426B00'],
              }}
              rootProps={{ 'data-testid': '1' }}
            />
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default GoogleChart;
