import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "./useAuth";

const queryClient = new QueryClient();

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  </AuthProvider>
);

export default AppProvider;
